<script setup>
  const props = defineProps({
    user: Object
  })

  const avatarUrl = computed(() => {
    return props.user?.avatar?.url ? useStrapiMedia(props.user.avatar.url) : null
  })

  const initials = computed(() => {
    return `${props.user.firstName?.[0].toUpperCase() ?? ''}${props.user.lastName?.[0].toUpperCase() ?? ''}`
  })
</script>

<template>
  <div
    class="flex aspect-square items-center justify-center rounded-full bg-zinc-300 bg-cover bg-center text-tarawera-900 shadow"
    :style="{
      backgroundImage: avatarUrl ? `url('${avatarUrl}')` : undefined
    }"
  >
    <FontAwesomeIcon class="text-zinc-500" v-if="!props.user" icon="fa-solid fa-question" />
    <template v-else-if="!avatarUrl">{{ initials }}</template>
  </div>
</template>
